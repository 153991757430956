@tailwind base;
@tailwind components;
@tailwind utilities;

.chrome-picker {
    box-shadow: none !important;
}

.Toastify__toast {
    min-height: 20px;
    @apply shadow-md border text-sm font-sans text-center text-gray-200 bg-gray-800 rounded-md;
}

.Toastify__toast-container {
    width: 190px;
}

.Toastify__toast-body {
    @apply p-0 m-0;
}

.col-left {
    height: calc(100vh - 40px);
}

@media (max-width: 1024px) {
    .col-left {
        height: auto;
    }
  }

.react-responsive-modal-closeButton {
    top: 10px;
    width: 21px;
    right: 14px;
}